.session-page__wrapper {
    // padding-bottom: 50px;
    position: relative;

    .auto-play-switch {
        align-items: center;
        position: relative;

        span {
            display: block;
            padding-right: 8px;
            font-size: 18px;
        }

        .switch_wrapper {
            z-index: 100;
            border-radius: 50%;
            background: white;
            padding: 20px 6px;

            .auto-switch {
                display: flex !important;
            }
        }
    }

    .session-page__header {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        font-weight: bold;
        font-size: 24px;

        .couese-name {
            &:after {
                content: '';
                border-left: 1px solid;
                margin: 0 20px;
            }
        }
    }

    .section-title {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
    }

    .session-description {
        text-align: left;
        padding-bottom: 20px;
    }

    .personal-msg__wrapper {
        text-align: left;
        padding-bottom: 20px;
    }

    .equipment__wrapper {
        padding: 12px;
        

        .equipment-img {
            width: 40px;
            height: 40px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .replay-btn__wrapper {
        position: absolute;
        z-index: 10;
        width: 100px;
        top: 101px;
        left: 50%;
        transform: translate(-50%, 0);

        div {
            font-weight: 600;
            font-size: 16px;
            color: white;
        }

        button {
            padding: 6px 16px;
            width: 76px;
            height: 26px;
            font-size: 14px;
        }
    }

    .session-task__section {
        position: relative;

        .personal-msg__wrapper {
            position: absolute;
            background: transparent;
            right: 0;
            padding: 0;
            top: 15px;

            .avatar {
                margin-right: 16px;
            }
        }
    }

    .session-tasks__wrapper {
        padding: 20px 0;
    
        .task-button {
            border: 0;
            background: none;
            width: 100%;
            padding: 12px 0px;
        }
    
        .task-section__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .task-image {
            width: 170px;
            height: 212px;
            position: relative;

            &.done {
                &::after {
                    content: "";
                    position: absolute;
                    width: 170px;
                    height: 212px;
                    background: #343434;
                    opacity: 0.7;
                    top: 0;
                    right: 0;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
    
            img {
                width: 100%;
                height: 100%;
            }
        }
    
        .task-details {
            // margin-left: 20px;
            text-align: center;
            margin-top: 10px;
            font-size: 18px;
            font-family: 'Noir No1';
    
            .task-description {
                font-weight: bold;
                // min-height: 50px;
                margin-bottom: 6px;
            }
        }
    }

    .personal-video__wrapper {
        position: relative;
        text-align: left;

        button {
            padding: 0;
            width: 70px;
            height: 88px;
            background: #FCFCFC;
            border-radius: 4px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .custom-arrow {
        background: transparent;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        z-index: 10;
        right: 0;
        top: 125px;

        &.slick-disabled {
            opacity: 0.3;
            &::before {
                opacity: 1;
            }
        }

        &::before {
            content: '';
            background: #283C3C;
            width: 34px;
            height: 25px;
            position: absolute;
            border-radius: 50%;
            z-index: -1;
        }
    }

}

