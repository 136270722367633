.welcome-modal__wrapper {
    text-align: center;
    padding: 32px 48px;

    .title {
        font-weight: 700;
        font-size: 34px;
        line-height: 32px;
    }

    .description {
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        padding: 25px 0px;
        max-width: 420px;
        margin: auto;
    }

    span {
        display: block;
    }

    .btn {
        width: 178px;
        height: 48px;
        font-size: 16px;
    }
}
