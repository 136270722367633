.tips__modal{
    .section-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
    }
    .tips-content {
        div {
            font-size: 18px;
            display: list-item;
            margin:  0 0 4px 18px;
        }
    }

    .countdown-wrapper {
        font-size: 18px;
        font-weight: bold;
        color: $disabledColor
    }

    .btn {
        height: 48px;
        font-size: 18px;
        font-weight: 400;
    }
}
