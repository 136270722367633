.courses-page__wrapper {
    justify-content: center;
    .welcom-msg {
        font-size: 24px;
        color: $primaryColor;
        padding: 14px;
        display: flex;
        justify-content: center;
        font-weight: bold;
        margin: 20px 0;
    }

    .course-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;

    }

    .image-wrapper {
        width: 831px;
        height: 183px;
        background: #D9D9D9;
        margin: 46px auto 56px auto;

        img {
            width: 100%;
            height: auto;
        }
    }
    .course-name {
        text-align: center;
        font-size: 28px;
        font-weight: bold;
    }

    .description {
        text-align: center;
        font-size: 18px;
        max-width: 665px;
        margin: auto;
    }

    .start-btn_wrapper {
        // max-width: 480px;
        margin: auto;

        .btn {
            // margin-bottom: 20px;
        }
    }

    .course-sessions__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 20px;

        .course-sessions__section {
            display: flex;
            flex-direction: column;
            margin: 20px;
            max-width: 100px;
        }

        .session-button {
            border: 0;
            background: none;
        }

        span {
            margin-top: 18px;
        }
    }
}