.back-confirmation__wrapper {
    text-align: center;
    padding: 28px;

    .title {
        font-family: 'Noir No1';
        font-weight: 600;
        font-size: 28px;
        line-height: 1.5;
        color: $fontColor;
    }

    .btn {
        height: 48px;
        font-size: 18px;
        font-weight: 400;
        // margin-top: 80px;
    }
}
