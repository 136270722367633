.sticky-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 38px;
    z-index: 10;
}

.glow-effect {
    background: white;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        box-shadow: 0 -1px 9px 11px rgb(255 255 255 /90%);
        margin-bottom: 20px;
    }
}

div,
span {
    font-family: "Noir No1";
    color: $fontColor;
}

.placeholder-wave {
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.4;
    margin: auto;
}

.mask {
    // display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: #343434;
    opacity: 0.7;
}

.slick-list> div {
    margin-left:0;
}

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

.log-out {
    display: flex;
    align-items: center;
    padding: 50px 0 0;
    justify-content: flex-end;

    &.overlap {
        position: absolute;
        right: 0;
        padding-top: 36px;
        width: 225px;
        z-index: 100;
    }

    .btn {
        max-width: 225px;
        margin: 0;

        font-size: 18px;
        max-width: 180px;
        line-height: 1.5;
        height: 48px;
    }
}
