@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~bootstrap/scss/bootstrap';
// @import './bootstrap/bootstrap-grid.min.css';

// some general style
@import './variable.scss';
@import './buttons.scss';
@import './common.scss';

@import './modal.scss';

// pages style:

@import '../pages/CoursesInfo/courses-info.scss';
@import '../pages/Login/login.scss';
@import '../pages/SessionDetails/session-details.scss';
@import '../pages/Task/task.scss';
@import '../pages//Done/done-view.scss';


// components style :

@import '../components/Feedback/feedback.scss';
@import '../components/InfoPopup/infoPopup.scss';
@import '../components//Svg/svg.scss';
