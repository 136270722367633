.modal-header {
    position: absolute;
    border: 0;
    right: 0;
    z-index: 10;
}

.instuction-modal__wrapper {
    align-items: center;
    padding: 40px 0;

    .instructions__wrapper {
        height: 365px;
        overflow-y: auto;

        &.with-personal-msg {
            height: 235px;
            margin-bottom: 8px;
        }

        .section-title {
            font-weight: bold;
        }
    }
    .image {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .content {
        .title {
            padding-bottom: 16px;
            font-family: 'Noir No1';
            font-weight: 700;
            font-size: 28px;
            line-height: 1.5;
            color: $fontColor;
        }
    }

    .action-btn {
        button {
            background: #C9C9C9;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            justify-content: center;
            align-items: center;
            display: flex;

            &:disabled {
                opacity: 0.3;
            }
        }
    }
}

.personal-msg__wrapper {
    padding: 16px;
    background: #F8F9F9;

    .avatar {
        position: relative;
        width: 30px;
        height: 30px;
        background: gray;
        border-radius: 50%;
        margin-right: 20px;
        flex: 0 0 auto;
        margin-bottom: 12px;

        svg {
            position: absolute;
            right: -5px;
            bottom: 4px;
        }
    }
}
