.chevron-svg {
    &.right {
        transform: rotate(180deg);
    }
}

.play-svg {
    background: #23A377;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}