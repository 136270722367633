.task-view-page {
    margin: auto;
    justify-content: center;
    position: relative;

    .play-task-view,
    .replay-task-view {
        padding-top: 30px;
        height: 100vh;
        display: flex;

        .footer-wrapper {
            position: absolute;
            bottom: -1px;
            padding: 0;
            z-index: 1;
            left: 0;
            width: 100%;
            background: none;
        }

        .glow-effect {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.98) 30.77%, rgba(255, 255, 255, 0.28125) 71.45%, rgba(255, 255, 255, 0) 97.85%);

            &::before {
                background: none;
                box-shadow: 0px 20px 20px 20px rgb(255 255 255 / 50%);
                margin-bottom: 0;
            }
        }

        .countdown {
            position: absolute;
            right: calc(50% - 177px);
            color: #343434;
            font-size: 25px;
        }
    }

    .back-to-session {
        text-align: left;
        padding-top: 20px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        cursor: pointer;

        height: 92px;
        display: flex;
        align-items: center;
        padding: 0 0 0 0;
        width: 175px;

        &.overlap-back {
            position: absolute;
            left: 30px;
            z-index: 1;
            height: auto;
            padding-top: 20px;
        }
    }

    .view-header {
        margin-bottom: 20px;
        text-align: left;
    }

    .task-details {
        margin-top: 50px;
    }

    .related-session {
        font-size: 20px;
        font-weight: bold;
        margin-right: 6px;
    }

    .task-position {
        font-size: 20px;
        margin-left: 6px;
    }

    .task-name {
        display: flex;
        margin-bottom: 20px;
        text-align: left;
        align-items: center;
        margin-top: 12px;

        .prepare {
            margin-right: 8px;
        }
    }

    .task-name__value {
        font-size: 28px;
        font-weight: bold;
    }

}

.current-task-image {
    max-width: 573px;
    max-height: 755px;
    margin: auto;

    img {
        width: 100%;
        height: auto;
    }
}

.task-prepare-view__wrapper {
    text-align: left;

    &.manual {
        max-height: calc(100vh - 222px);

        .current-task-image {
            width: calc(100vh - 338px);;
        }

        .content-wrapper {
            max-width: 334px;
        }

        .task-name {
            margin-top: 50px;
        }

        .instructions__wrapper {
            max-height: calc(100vh - 308px) !important;
            overflow-y: auto;
            text-align: left;
            padding-bottom: 52px;

            &.with-personal-msg {
                max-height: calc(100vh - 485px) !important;
            }

            .section {
                margin-bottom: 8px;
                margin-right: 20px;
            }

            .section-title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 8px;
            }
        }
    
        .instructions-content,
        .tips-content {
            div {
                display: list-item;
                margin:  0 0 4px 18px;
            }
        }
    }
}

.task-prepare-view__wrapper,
.task-view__wrapper,
.task-play-view__wrapper {
    max-width: 1000px;
    margin: auto;

    .content-wrapper {
        flex: 1;
        margin-left: 32px;
    }

    .countdown {
        font-size: 32px;
        margin: 12px auto 4px auto;
    }

    .task-start-btn__wrapper {
        // max-width: 450px;
        margin: auto;
    }

    .control-buttons__wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-bottom: 20px;
    }

    .repeat-button {
        position: absolute;
        left: calc(50% - 128px);
    }

    .caret-button {
        background: $primaryColor;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 12px;
    }
}

.task-transition-view__wrapper {
    .task-transition-view__header {
        font-size: 24px;
        font-weight: bold;
        color: $fontColor;
        margin: 20px 20px 12px;
    }

    .current-task-image {
        width: calc(100vh - 370px);
        // height: 504.5px;
    }

    .task-start-btn__wrapper {
        button {
            height: 64px;
            max-width: 375px;
            margin: 0 12px;
            font-size: 18px;
        }
    }
}

.task-play-view__wrapper {
    .current-task-image {
        width: calc(100vh - 212px);
        max-width: 630.95px;
        max-height: 787.83px;
        position: relative;
    }

    .content {
        display: flex;
        margin: auto;
    }
    .content-instruction__wrapper {
        max-width: 334px;
        margin-left: 32px;
    }

    .instructions__wrapper {
        max-height: calc(100vh - 150px) !important;
        overflow-y: auto;
        text-align: left;
        padding-bottom: 52px;

        &.with-personal-msg {
            max-height: calc(100vh - 304px) !important;
        }

        .section {
            margin-bottom: 8px;
            margin-right: 20px;
        }

        .section-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
        }
    }

    .show-instruction {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: white;
        position: absolute;
        right: 32px;
        background: #4678B4;
        border-radius: 100px;
        padding: 6px 16px;
        top: 32px;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }
    }

    .content_personal-msg__wrapper {
        position: absolute;
        background: transparent;
        padding: 0;
        top: 32px;
        left: 32px;
        z-index: 1;

        .avatar {
            margin: 0;
            width: 46px;
            height: 46px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.auto-prepare-view {
    margin-top: 10px;

    .related-session {
        font-size: 28px;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .task-position {
        margin: 0;
    }

    .current-task-image {
        position: relative;
        width: calc(100vh - 484px);
        height: auto;
        max-width: 303.22px;
        max-height: 755px;
    }

    .task-name {
        font-size: 18px;
        margin-top: 12px;
        margin-bottom: 6px;
        flex-direction: column;

        .task-name__value {
            font-size: 24px;
        }
    }

    .show-instruction {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        position: absolute;
        right: 16px;
        background: #4678B4;
        border-radius: 50%;
        bottom: 16px;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }
    }

}

.personal-msg__modal {
    padding: 50px 65px 65px;

    .personal-msg__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent;

        .avatar {
            width: 52px;
            height: 52px;
            margin: 0;
        }
    }

    .personal-msg_content {
        font-size: 28px;
        text-align: center;
        margin-bottom: 8px;
    }

    .btn {
        width: 123px;
        height: 48px;
    }
}

// @media screen and ( max-height: 820px ) {
//     .task-play-view__wrapper {
//         .current-task-image {
//             width: 450.95px;
//             height: 562.83px;
//         }
//     }
    
// }