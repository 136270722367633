.login-page__wrapper {
    padding-top: 50px;

    .logo {
        margin: 0 50px 100px;
        width: 184px;
        height: 45px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .welcom-msg {
        font-weight: 700;
        font-size: 40px;
        line-height: 45px;
    }

    .description {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        max-width: 480px;
        margin: 16px auto 80px;
    }

    .help-link {
        display: inline-block;
        font-size: 18px;
        line-height: 20px;
        color: $secondaryColor;
        font-weight: normal;
        margin: 28px 0;

        &:hover {
            cursor: pointer;
        }
    }

    .form {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: auto;
    }

    .error {
        color: $error;
        padding-top: 8px;
    }

    .btn {
        margin-top: 32px;
    }

    .otp-input {
        margin: 0 9px;
        text-align: center;
        width: 52.7px !important;
        height: 60px;
        left: 65.65px;
        top: 196px;
        border: 2px solid #C9C9C9;
        border-radius: 5px;

        &:focus,
        &:active {
            border: 2px solid #4678B4;
        }
    }
}