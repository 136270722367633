.btn {
    display: flex;
    border: 0;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    padding: 14px;
    width: 100%;
    font-family: 'Noir No1';

    &.btn-primary {
        background-color: $primaryColor;
        max-width: 550px;
        margin: auto;
        height: 66px;
        line-height: 28px;
        font-size: 24px;

        &:active {
            background-color: $primaryColor;
        }

        &:disabled {
            background: #9A9A9A;
        }
    }
    
    &.btn-secondary {
        background-color: $secondaryColor;

        &:active {
            background-color: $secondaryColor;
        }
    }

    &.btn-tertiary {
        padding: 4px 7px;
        border: 1px solid #343434;
        border-radius: 100px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #343434;
    }

    &.btn-primary__light {
        color: black;
        background-color: $primaryLightColor;

        &:active {
            background-color: $primaryLightColor;
        }

    }
}

.icon-btn {
    border: 0;
    background: none;
}

.link-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $secondaryColor;
    border: 0;
    background: none;
}

