.general-feedback__wrapper {
    padding: 24px 48px;

    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
    }

    .description {
        font-size: 18px;
        line-height: 30px;
        padding: 16px 0;
    }

    textarea {
        width: 100%;
        border: 1px solid #C9C9C9;
        border-radius: 5px;
    }

    .btn {
        font-size: 14px;
        line-height: 20px;
        height: 48px;
    }
}
