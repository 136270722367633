.done-viwe__wrapper {
    text-align: center;
    padding: 40px;

    .title {
        font-family: 'Noir No1';
        font-weight: 700;
        font-size: 34px;
        line-height: 37px;
        color: $fontColor;
    }

    .subtitle {
        margin: 40px 0;
        font-family: 'Noir No1';
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        color: $fontColor;
    }

    .img-wrapper {
        width: 375px;
        height: 280px;
        margin: auto;
        margin-bottom: 80px;

        img {
            width: auto;
            height: auto;
        }
    }

    .done-work-msg {
        font-size: 20px;
        font-family: 'Noir No1';
        font-weight: 400;
        font-size: 32px;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    .stars-wrapper {
        svg {
            margin: 0 6px;
        }
    }

}
