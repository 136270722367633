@font-face {
  font-family: 'Noir No1';
  src: url('NoirNo1.woff') format('woff'), url('NoirNo1.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noir No1';
  src: url('NoirNo1-Demibold.woff') format('woff'),
    url('NoirNo1-Demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Noir No1';
  src: url('NoirNo1-Bold.woff') format('woff'),
    url('NoirNo1-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
