.need-help-modal__wrapper {
    text-align: left;
    padding: 12px;
    font-family: 'Noir No1';

    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: $fontColor;
    }

    .description {
        padding: 20px 0;
        list-style: none;
        counter-reset: item;
        font-size: 24px;
        line-height: 36px;

        li {
            counter-increment: item;
            margin-bottom: 5px;

            &:before {
                margin-right: 10px;
                content: counter(item)')';
                width: 1.2em;
                text-align: center;
                display: inline-block;
              }
          }
    }

    .btn {
        max-width: 178px;
        height: 48px;
    }
}
