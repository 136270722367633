.feedback__wrapper {
    max-width: 800px;
    margin: auto;
    padding: 50px;

    textarea {
        background: #F8F9F9;
        padding: 12px;
        border-radius: 6px;
        margin: 20px 0;
    }

    label {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
    }

    .avatar {
        width: 48px;
        height: 48px;
        background: gray;
        border-radius: 50%;
        margin-right: 20px;
        flex: 0 0 auto;
        margin-bottom: 12px;
    }

    .subtitle {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        text-align: left;
    }

    .feedback-chips__wrapper{
        // border-bottom: 1px solid #C9C9C9;
        margin-bottom: 20px;
        padding-bottom: 16px;
    }

    .feedback-chips {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;

        .chip {
            display: flex;
            justify-content: center;
            padding: 9px;
            border: 1px solid #343434;
            color: #343434;
            border-radius: 30px;
            margin: 6px;
            min-width: 83px;

            &.selected {
                background: $primaryLightColor;
                border-color: $primaryLightColor;
                color: $fontColor;
                font-weight: 600;
            }

            &:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
        }
    }


}

.feedback__task {
    &-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-image {
        max-width: 60px;
        max-height: 60px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
    }

    &-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #343434;
        margin-left: 12px;
    }

    .feedback-chips__wrapper {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
    }

    .link-btn__text {
        margin-right: 8px;
    }
}

.task-feedback-section {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #C9C9C9;
    margin-bottom: 40px;
    padding-bottom: 27px;

    .feedback-chips__wrapper {
        border: 0;
        margin-bottom: 0;
        justify-content: flex-start;
    }

    .btn-wrapper {
        width: 80%;
        margin: auto;
    }

}
