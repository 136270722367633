.info-popup__wrapper {
    position: absolute;
    width: 337px;
    right: 9px;
    top: 85px;
    background: $primaryLightColor;
    border-radius: 5px;
    padding: 34px;
    z-index: 110;

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid $primaryLightColor;
        z-index: 1;
        top: -11px;
        right: 12px;
    }

    .title {
        color: #000000;
        font-size: 20px;
        line-height: 28px;
    }

    .content {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        padding: 20px 0px;

        span {
            padding-right: 0;
        }
    }

    .btn {
        width: 94px;
        height: 46px;
        font-size: 16px;
        line-height: 1;
    }

}
