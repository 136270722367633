@import './modals/thankYouModal.scss';
@import './modals/instructionModal.scss';
@import './modals/backConfirmationModal.scss';
@import './modals/needHelpModal.scss';
@import './modals/welcomeModal.scss';
@import './modals/generalFeedbackModal.scss';
@import './modals/importantTipsModal.scss';

.command-view__wrapper {
    .commands-details {
        .description {
            font-weight: 600;
            font-size: 18px;
        }

        .command {
            margin-left: 18px;
        }
    }

    .h-line {
        border-bottom: 1px solid $disabledColor;
        width: 50%;
        height: 25px;
        margin-bottom: 25px;
    }
}