.thank-you-modal__wrapper {
    text-align: center;
    padding: 40px;

    .title {
        font-family: 'Noir No1';
        font-weight: 700;
        font-size: 34px;
        line-height: 37px;
        color: $fontColor;
    }

    .subtitle {
        margin-bottom: 80px;
        margin-top: 40px;
        font-family: 'Noir No1';
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        color: $fontColor;
    }

    .img-wrapper {
        width: 210px;
        height: 280px;
        margin: auto;
        margin-bottom: 80px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
